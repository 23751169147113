import React from 'react';
// import { ICartResources } from '../../definition-extensions/cart.ext.props.autogenerated';
// import { ICheckoutResources } from '../../definition-extensions/checkout.ext.props.autogenerated';

interface IEveLinePaginationProps {
    nextPage: (e: React.MouseEvent<HTMLButtonElement>) => void;
    prevPage: (e: React.MouseEvent<HTMLButtonElement>) => void;
    changePage: (page: number) => void;
    currentPage: number;
    numPages: number;
    prev: string;
    next: string;
}

/**
 * Renders pagination navigation on the page, which displays 4 buttons that allow
 * user to navigate to the first, last, next or previous pages. Also displays
 * the current page out of the total number of pages. ( ie. 5/7 )
 *
 * @param {(e: React.MouseEvent<HTMLButtonElement>) => void} nextPage
 * @param {(e: React.MouseEvent<HTMLButtonElement>) => void} prevPage
 * @param {(page: number) => void} changePage
 * @param {number} currentPage
 * @param {number} numPages
 * @param {ICartResources} resources
 * @return {*}  {React.ReactNode}
 */
export const EveLinePagination: React.FC<IEveLinePaginationProps> = ({
    nextPage,
    prevPage,
    changePage,
    currentPage,
    numPages,
    prev,
    next
}) => {
    const firstPage: number = 1;
    const baseClassName = 'eve-pagination';

    return (
        <div className={`${baseClassName}`}>
            {numPages > 1 && (
                <>
                    <>
                        <button
                            disabled={currentPage === firstPage}
                            className={`${baseClassName}__btn btn-first msc-btn ${currentPage === firstPage ? 'btn-disabled' : ''}`}
                            onClick={() => changePage(1)}
                        >
                            {`${firstPage}`}
                        </button>
                        <button
                            disabled={currentPage === firstPage}
                            className={`${baseClassName}__btn btn-prev msc-btn ${currentPage === firstPage ? 'btn-disabled' : ''}`}
                            onClick={prevPage}
                        >
                            {prev}
                        </button>
                    </>
                    <div className={`${baseClassName}__count`}>
                        <span className={`${baseClassName}__count-text`}>
                            {currentPage} / {numPages}
                        </span>
                    </div>
                    <>
                        <button
                            disabled={currentPage === numPages}
                            className={`${baseClassName}__btn btn-next msc-btn ${currentPage === numPages ? 'btn-disabled' : ''}`}
                            onClick={nextPage}
                        >
                            {next}
                        </button>
                        <button
                            disabled={currentPage === numPages}
                            className={`${baseClassName}__btn btn-last msc-btn ${currentPage === numPages ? 'btn-disabled' : ''}`}
                            onClick={() => changePage(numPages)}
                        >
                            {`${numPages}`}
                        </button>
                    </>
                </>
            )}
        </div>
    );
};
